import React from 'react'
import GalleryWithLightbox from '../../../components/gallery-with-lightbox'
import Layout from '../../../components/layout/layout'
import Header from '../../../components/header/header'
import Nav from '../../../components/nav/nav'
import Footer from '../../../components/footer/footer'

import image1 from '../../../assets/images/albums/paintings/early-works/el-centinela.jpg'
import image2 from '../../../assets/images/albums/paintings/early-works/wish-you-were-here.jpg'
import image3 from '../../../assets/images/albums/paintings/early-works/bianco-e-nero.jpg'
import image4 from '../../../assets/images/albums/paintings/early-works/i-know-what-youre-doing.jpg'
import image5 from '../../../assets/images/albums/paintings/early-works/army-of-seeds.jpg'
import image6 from '../../../assets/images/albums/paintings/early-works/el-ombu-y-el-pajaro-azul_40x30in-2013.jpg'
import image7 from '../../../assets/images/albums/paintings/early-works/cuervo-azabache-con-hongos_acrylic-mixed-media-diptych-8x12in.jpg'
import image8 from '../../../assets/images/albums/paintings/early-works/reinventing-nature-2010.jpg'
import image9 from '../../../assets/images/albums/paintings/early-works/orange-bicycle_2008.jpg'
import image10 from '../../../assets/images/albums/paintings/early-works/wrong-banana_2008.jpg'
import image11 from '../../../assets/images/albums/paintings/early-works/royal-fruit-acrylic-on-canvas-36x36in.jpg'
import image12 from '../../../assets/images/albums/paintings/early-works/why-is-there-an-indian-on-my-cake_2008.jpg'
import image13 from '../../../assets/images/albums/paintings/early-works/picnic-i-2005.jpg'
import image14 from '../../../assets/images/albums/paintings/early-works/julia-is-going-for-a-walk_2007.jpg'
import image15 from '../../../assets/images/albums/paintings/early-works/me-estoy-pareciendo-a-ti_acrylic-on-canvas-36x24in.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <GalleryWithLightbox
        photos={PHOTO_SET}
        direction='column'
        margin={8}
      />
    </main>
    <Footer />
  </Layout>
)

const PHOTO_SET = [
  {
    src: image1,
    width: 737,
    height: 960,
    caption: 'The Sentinel - acrylic on canvas - 54"x42" - Private Collection'
  },
  {
    src: image2,
    width: 764,
    height: 960,
    caption: 'Wish You Were Here - acrylic & ceramic on canvas w/ black frame - 29"x23"'
  },
  {
    src: image3,
    width: 960,
    height: 381,
    caption: 'Bianco e nero - diptych acrylic, ceramic & metallic paper - 40"x16"'
  },
  {
    src: image4,
    width: 960,
    height: 720,
    caption: 'I Know What You\'re Doing - Private Collection'
  },
  {
    src: image5,
    width: 727,
    height: 960,
    caption: 'Army of Seeds - acrylic on canvas - 48"x36"'
  },
  {
    src: image6,
    width: 717,
    height: 960,
    caption: 'El ombú y el pájaro azul - acrylic & ceramic on canvas - 40"x30"'
  },
  {
    src: image7,
    width: 960,
    height: 638,
    caption: 'Cuervo Azabache con Hongos - Private Collection'
  },
  {
    src: image8,
    width: 960,
    height: 746,
    caption: 'Reinventing Nature - acrylic on canvas and mixed media - 62.5"x80.5"'
  },
  {
    src: image9,
    width: 960,
    height: 949,
    caption: 'The Orange on a Bicycle - acrylic on canvas - 24"x24" - Private Collection'
  },
  {
    src: image10,
    width: 960,
    height: 960,
    caption: 'What\'s Wrong With This Banana? - acrylic on canvas - 24"x24"'
  },
  {
    src: image11,
    width: 953,
    height: 960,
    caption: 'Royal Fruit - acrylic on canvas - 36"x36 - Private Collection"'
  },
  {
    src: image12,
    width: 956,
    height: 960,
    caption: 'Why Is There An Indian On My Cake? - acrylic on canvas - 24"x24"'
  },
  {
    src: image13,
    width: 727,
    height: 960,
    caption: 'Picnic I - acrylic on canvas - 48"x36"'
  },
  {
    src: image14,
    width: 955,
    height: 960,
    caption: 'Julia is Going for a Walk - acrylic on canvas - 36"x36" - Private Collection'
  },
  {
    src: image15,
    width: 631,
    height: 960,
    caption: 'Me estoy pareciendo a tí - acrylic on canvas - 36"x24"'
  }
]
